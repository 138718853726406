//邓亚鑫-V2.2.1
<template>
  <section style="margin: 24px 24px 0">
    <!-- <section class="chapter-total"> -->
    <!-- <a-row type="flex" justify="space-between" class="chapter-row">
        <a-col
          v-for="(item, index) of chapterArr"
          :class="index + 1 == chapterArr.length ? 'total-item' : 'total-item total-mg'"
          :key="index"
          :style="`background-image: url(${require('@/assets/images/home-bg' + (index + 1) + '.png')})`"
          @click="$router.push(item.href)"
        >
        <div style="display:inline-block">
          <p class="item-name">{{ item.name }}</p>
          <p class="item-count">{{ item.count }}</p>
        </div>
        <div style="display:inline-block">
          <i class="iconfont icon-yongyinjiluzongshu icons" v-if="index==0"></i>
          <i class="iconfont icon-yongyinshenqingzongshu icons" v-if="index==1"></i>
          <i class="iconfont icon-gaiyinzongshu icons" v-if="index==2"></i>
          <i class="iconfont icon-zhiwengaiyinzongshu icons" v-if="index==3"></i>
          <i class="iconfont icon-yiguidangzongshu icons" v-if="index==4"></i>
        </div>
        </a-col>
      </a-row> -->
    <ul class="chapter-total">
      <li
        v-for="(item, index) of chapterArr"
        :class="index + 1 == chapterArr.length ? 'total-item' : 'total-item total-mg'"
        :key="index"
        :style="`background-image: url(${require('@/assets/images/home-bg' + (index + 1) + '.png')})`"
        @click="hrefHandle(index, item.href)"
      >
        <div style="display:inline-block">
          <p class="item-name">{{ item.name }}</p>
          <p class="item-count">{{ item.count ?? '' }}</p>
        </div>
        <div style="display:inline-block">
          <i :class="['iconfont', icons[index], 'icons']"></i>
          <!-- <i class="iconfont icon-yongyinjiluzongshu icons" v-if="index == 0"></i>
          <i class="iconfont icon-yongyinshenqingzongshu icons" v-if="index == 1"></i>
          <i class="iconfont icon-gaiyinzongshu icons" v-if="index == 2"></i>
          <i class="iconfont icon-zhiwengaiyinzongshu icons" v-if="index == 3"></i>
          <i class="iconfont icon-yiguidangzongshu icons" v-if="index == 4"></i> -->
        </div>
      </li>
    </ul>
    <!-- </section> -->
    <not-approval-table :isHome="true" />
  </section>
</template>

<script>
import { companyTotal } from '@/apis/dashboard'
import { onActivated, onMounted, onUpdated, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import notApprovalTable from '@/views/approval/components/notApprovalTable'
export default {
  components: {
    notApprovalTable
  },
  setup() {
    const store = useStore()
    const chapterArr = ref([
      {
        name: '用印记录总数',
        count: null,
        color: '#7d80bb',
        index: 'recordSum',
        href: '/seal/applyList'
      },
      {
        name: '用印申请总数',
        count: null,
        color: '#75c0ce',
        index: 'applySum',
        href: '/seal/apply'
      },
      {
        name: '盖印总数',
        count: null,
        color: '#e56a74',
        index: 'imprintSum',
        href: '/seal/applyList'
      },
      {
        name: '',
        count: null,
        color: '#7d80bb',
        index: 'warn',
        href: 'https://szyz.jdsoft.vip'
      }
      // {
      //   name: '指纹盖印总数',
      //   count: 0,
      //   color: '#515bd4',
      //   index: 'fingerprintSum',
      //   href: '/seal/applyList/?activeKey=1'
      // },
      // {
      //   name: '已归档总数',
      //   count: 0,
      //   color: '#ff9d76',
      //   index: 'archiveSum',
      //   href: '/seal/applyList/?sealStatus=5'
      // }
    ])
    const icons = ref([
      'icon-yongyinjiluzongshu',
      'icon-yongyinshenqingzongshu',
      'icon-gaiyinzongshu'
      // 'icon-zhiwengaiyinzongshu',
      // 'icon-yiguidangzongshu'
    ])
    const state = reactive({
      // reloadPageRouter:store.state.globalData.reloadPageRouter
    })
    const route = useRouter()
    const queryTotal = async () => {
      const res = await companyTotal()
      chapterArr.value.forEach(item => {
        item.count = res.data[item.index]
      })
      chapterArr.value[3].count = '数据统计预警'
    }
    const hrefHandle = (index, href) => {
      console.log('hrefHandle', index, href)
      if (index == 3) {
        window.open(href, 'target')
      }
    }
    onMounted(() => {
      queryTotal()
    })
    onUpdated(() => {
      let page = localStorage.getItem('reloadPageRouter')
      if (page == '/dashboard/analysis') {
        queryTotal()
      }
    })
    return {
      chapterArr,
      icons,
      queryTotal,
      state,
      hrefHandle
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.ant-row) {
  width: 100%;
}
.chapter-total {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background-color: #ffffff;
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
  }
  .total-item {
    flex: 1;
    background-size: cover;
    background-position: right;
    padding: 24px 16px;
    border-radius: 4px;
    min-width: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icons {
      font-size: 42px;
      color: rgba(255, 255, 255, 0.1);
    }
    .item-name {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
      padding-bottom: 6px;
    }
    .item-count {
      height: 32px;
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      line-height: 32px;
    }
  }
  .total-item:last-child {
    cursor: pointer;
    .item-count {
      font-size: 24px;
    }
  }
  .total-mg {
    margin-right: 25px;
  }
  .chapter-row {
    flex-flow: nowrap;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
